.cat_grid_container {
  display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    grid-auto-flow: row dense;
    justify-content: start;
    grid-gap: 20px;
    margin: 30px 0px;
    grid-column-start: span 2;
    padding-left: var(--Left-Padding);
}
.cat_thumb {
  grid-column: auto/span 1;
  grid-row: auto/span 1;
  height: 75px;
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  transition: all 0.1s;
 
}
.thumb_1x2{
    aspect-ratio: 12 / 5;
    transition: all 0.1s;
    box-sizing: border-box;
}
.thumb_1x2:hover {
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.15);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.15);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.15);
  transition: all 200ms ease-in;
  transform: scale(1.15);
}
/* Cat */
.cat_last_icon{
  align-items:center;
  width: 75px;
  height:75px;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-color: var(--white);

}
.cat_con  {
  align-items:center;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  align-content: center;
  justify-content: stretch;
  background-color: var(--white);
}
.cat_part1 {
  width: 162px;
  height: 75px;
}
.cat_part2 {
  width: 205px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  padding: 0px 10px;
  color: var(--darkblack);
  font-family: "Nunito", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.view_all {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.view_all a {
  color: var(--yellow);
  font-weight: 800;
  font-size: 23px;
  text-decoration: none;
}


@media  (max-width: 450px) {
  .cat_thumb{
    height: auto;
}
  .cat_grid_container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding-right: 10px;
}
}

/* Media Query*/

/*
@media only screen and (min-width: 300px) {
}
@media only screen and (min-width: 360px) {
}
@media only screen and (min-width: 477px) {
}
@media only screen and (min-width: 597px) {
}
@media only screen and (min-width: 747px) {
}
@media only screen and (min-width: 897px) {
}
@media only screen and (min-width: 1047px) {
}
@media only screen and (min-width: 1197px) {
}
@media only screen and (min-width: 1347px) {
}
@media only screen and (min-width: 1497px) {
}
@media only screen and (min-width: 1645px) {
}
@media only screen and (min-width: 1797px) {
}
@media only screen and (min-width: 1947px) {
} */
